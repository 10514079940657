import Base from "../base/Base";

export default class InstallFieldInfo extends Base{

  name: string = "";
  dataType: boolean = false;

  assign(obj: any) {
    super.assign(obj);
  }

}
